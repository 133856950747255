import React from 'react';
import * as styles from './typography-bold.module.css';
import classnames from 'classnames';

const TypographyBold = ({ variant = 'standart', children }) => {
  return (
    <span className={classnames(styles.container, {
      [styles.standart]: variant === 'standart',
      [styles.mobile]: variant === 'mobile',
      [styles.desktop]: variant === 'desktop',
    })}>{children}</span>
  );
};

export default TypographyBold;
