import React from 'react';
import * as styles from './ready-to-become-astronaut-section.module.css';
import readyToBecomeAstronautVideo from './videos/Spaceport_Doors_Opening.mp4';

const ReadyToBecomeAstronautSection = () => {
  return (
    <section className={styles.container}>
      <video className={styles.video} autoPlay loop muted playsInline>
        <source src={readyToBecomeAstronautVideo} type='video/mp4' />
      </video>
      {/*<div className={styles.content}>*/}
      {/*  <h2 className={styles.title}>*/}
      {/*    READY*/}
      {/*    <TypographyBold> TO BECOME AN ASTRONAUT?</TypographyBold>*/}
      {/*  </h2>*/}
      {/*  <GradientLink className='gtm-wb-footer-application-sign-up' to='https://www.virgingalactic.com/fly-with-us'>SIGN UP</GradientLink>*/}
      {/*</div>*/}
    </section>
  );
};

export default ReadyToBecomeAstronautSection;
