import React from 'react';
import * as styles from './preview-section-inner.module.css';

const PreviewSectionInner = ({ children }) => {
  return (
    <div className={styles.container}>
      {children}
    </div>
  );
};

export default PreviewSectionInner;
