import React from 'react';
import * as styles from './page-title.module.css';

const PageTitle = ({ children }) => {
  return (
    <h1 className={styles.title}>
      {children}
    </h1>
  );
};

export default PageTitle;
