import  React, {useEffect} from "react"
import Layout from '../components/Layout';
import Container from '../components/Container';
import * as styles from './index.module.css';
import PageTitle from '../components/PageTitle';
import ReadyToBecomeAstronautSection from '../components/ReadyToBecomeAstronautSection';
import FindYourSpaceInHistorySection from '../components/FindYourSpaceInHistorySection';
import PreviewSection from '../components/PreviewSection';
import previewImg from './images/home-page-preview-section-bg.jpg';
import previewMobImg from './images/home-page-preview-section-bg-mob.jpg';
import MotivationSection from '../components/MotivationSection';
import TypographyBold from '../components/TypographyBold';
import PreviewSectionInner from '../components/PreviewSectionInner';
import { navigate } from "gatsby"

const IndexPage = () => {
  useEffect(() =>{
    navigate('https://events.virgingalactic.com/virgin-galactic-brochure/');
 },[]);
  return (
    <Layout pageTitle='Virgin Galactic | Experience Brochure' description='As THE commercial spaceline for Earth, our mission is to connect the world to the love, wonder and awe of space travel.'>
      <PreviewSection className={styles.previewSection} image={previewImg} mobImage={previewMobImg} alt='Night sky'>
        <Container size='sm'>
          <PreviewSectionInner>
            <div className={styles.pageTitleWrapper}>
              <PageTitle>
                <TypographyBold variant='mobile'>Pioneer </TypographyBold>
                humanity’s
                <TypographyBold> new space age.</TypographyBold>
              </PageTitle>
            </div>
          </PreviewSectionInner>
        </Container>
      </PreviewSection>
      <MotivationSection />
      <FindYourSpaceInHistorySection />
      <ReadyToBecomeAstronautSection />
    </Layout>
  );
};

export default IndexPage;
