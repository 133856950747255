import React from 'react';
import * as styles from './container.module.css';
import classnames from 'classnames';

const Container = ({ className, size = 'md', children }) => {
  return (
    <div className={classnames(styles.container, className, {
      [styles.lg]: size === 'lg',
      [styles.md]: size === 'md',
      [styles.sm]: size === 'sm',
    })}>
      {children}
    </div>
  );
};

export default Container;
