import React from 'react';
import * as styles from './find-your-space-in-history-section.module.css';
import Container from '../Container';
import SectionTitle from '../SectionTitle';
import backgroundImage from './images/background.jpg';
import mobileImage from './images/mobile-image.jpg';
import TypographyBold from '../TypographyBold';

const FindYourSpaceInHistorySection = () => {
  return (
    <section className={styles.container}>
      <Container>
        <div className={styles.inner}>
          <picture className={styles.imageWrapper}>
            <source srcSet={mobileImage} media='(max-width: 768px)'/>
            <img src={backgroundImage} className={styles.image} alt='View from the porthole'/>
          </picture>
          <div className={styles.description}>
            <div className={styles.descriptionInner}>
              <div className={styles.titleWrapper}>
                <SectionTitle>
                  Find{' '}
                  <TypographyBold variant='mobile'>
                    your space in
                    <TypographyBold variant='desktop'> history.</TypographyBold>
                  </TypographyBold>
                </SectionTitle>
              </div>
              <p className={styles.paragraph}>
                Fewer than 700 humans have ever experienced space. As a Virgin Galactic astronaut, your journey marks the dawn of a new space age, where leaving Earth’s atmosphere is an experience no longer reserved solely for professional astronauts.
              </p>
              <p className={styles.paragraph}>
                From the moment you join our global community of pioneering adventurers, to the day you earn your astronaut wings, to the years of telling the greatest story off Earth, your spaceflight can change the world for good.
              </p>
            </div>
          </div>
          <div className={styles.quoteWrapper}>
            <figure className={styles.quote}>
              <blockquote>
               <p className={styles.quoteContent}>
                 “Taking more and more passengers out into space will enable them, and us, to look both outwards and back, but with a fresh perspective in both directions.”
               </p>
              </blockquote>
              <figcaption className={styles.quoteCaption}>
                —Professor Stephen Hawking
              </figcaption>
            </figure>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FindYourSpaceInHistorySection;
