// extracted by mini-css-extract-plugin
export var container = "find-your-space-in-history-section-module--container--R1zLQ";
export var inner = "find-your-space-in-history-section-module--inner--jhBYU";
export var description = "find-your-space-in-history-section-module--description--yyVbf";
export var descriptionInner = "find-your-space-in-history-section-module--description-inner--izA2h";
export var titleWrapper = "find-your-space-in-history-section-module--title-wrapper--dK0MK";
export var paragraph = "find-your-space-in-history-section-module--paragraph--qP2ZR";
export var quoteWrapper = "find-your-space-in-history-section-module--quote-wrapper--KMQdn";
export var quote = "find-your-space-in-history-section-module--quote--pKYrV";
export var quoteContent = "find-your-space-in-history-section-module--quote-content--UdlEL";
export var quoteCaption = "find-your-space-in-history-section-module--quote-caption---Uhgf";
export var imageWrapper = "find-your-space-in-history-section-module--image-wrapper--Ev73b";
export var image = "find-your-space-in-history-section-module--image--d3Dj7";