import React from 'react';
import * as styles from './preview-section.module.css';
import classnames from 'classnames';

const PreviewSection = ({ className, alt, image, mobImage, children }) => {
  return (
    <section className={classnames(styles.container, className)}>
      <picture>
        <source srcSet={mobImage} media='(max-width: 768px) and (orientation: portrait)'/>
        <img src={image} className={styles.backgroundImage} alt={alt}/>
      </picture>
      <div className={styles.content}>{children}</div>
    </section>
  );
};

export default PreviewSection;
