import React from 'react';
import * as styles from './motivation-section.module.css';
import Container from '../Container';
import backgroundImg from './images/background.jpg';
import backgroundMobImg from './images/background-mob.jpg';


const MotivationSection = () => {
  return (
    <section className={styles.container}>
      <picture>
        <source srcSet={backgroundMobImg} media='(max-width: 768px)'/>
        <img src={backgroundImg} className={styles.backgroundImage} alt='Unity & Spaceport' />
      </picture>
      <Container size='sm'>
        <div className={styles.inner}>
          <p className={styles.paragraph}>
            Spaceflight has the unique ability to shift our perspectives, our technology, and even our trajectory as a species.
          </p>
          <p className={styles.paragraph}>
            As the spaceline for Earth, our mission is to transform access to space for the benefit of humankind; to reveal the wonder of space to more people than ever before.
          </p>
          <p className={styles.paragraph}>
            Join us, and help pioneer this exciting new space age for humanity.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default MotivationSection;
